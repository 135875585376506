import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';
import { cn } from '#/utils/cn';
import { Icon } from '../Icon';

export type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer m-0 h-4 w-4 shrink-0 rounded-sm border border-primary p-0 ring-offset-background focus:outline-none focus:ring-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground dark:border-white/80 dark:data-[state=checked]:bg-white/90 dark:data-[state=checked]:text-black/90',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className="relative m-0 flex h-full items-center justify-center p-0 text-current">
      <Icon
        name="check"
        className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 m-0 h-4 w-4 p-0"
        strokeWidth={2.5}
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
