import { useQueryClient } from '@tanstack/react-query';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { getGetCustomerCartQueryKey } from '#/http/api/customer/customer';
import type { GetCustomerCart200ProductsItem } from '#/http/api/generated.schemas';
import { Button } from '#/ui/button';
import { cn } from '#/utils/cn';
import { ToastAction } from '../ui/toast';
import { useToast } from '../ui/use-toast';

interface Props extends GetCustomerCart200ProductsItem {
  className?: string;
}

export function CartButton({ className, ...product }: Props) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const {
    addProductToCart,
    itemAdded,
    removeProductFromCart,
    setCurrentStep,
    productsOnCart,
    coupon,
    shippingPostcode,
    toggleCart,
  } = useCartStore((state) => ({
    toggleCart: state.toggleIsCartOpen,
    addProductToCart: state.addProductToCart,
    itemAdded: state.existsProductOnCart(product.product_id),
    removeProductFromCart: state.removeProductFromCart,
    setCurrentStep: state.setCurrentStep,
    productsOnCart: state.productsOnCart,
    shippingPostcode: state.isLocalPickup ? undefined : state.shippingPostcode,
    coupon: state.coupon,
  }));

  const handleToggleItemToOrder = async () => {
    setCurrentStep(1);

    if (itemAdded) {
      toast({
        variant: 'delete',
        title: 'Produto removido do carrinho!',
        description: `${product.title}`,
      });
      removeProductFromCart(product.product_id);
      return;
    }

    toast({
      variant: 'success',
      title: 'Produto adicionado ao carrinho!',
      description: `${product.title}`,
      action: (
        <ToastAction
          altText="Abrir carrinho"
          onClick={toggleCart}
        >
          Ver
        </ToastAction>
      ),
    });

    addProductToCart(product);

    await queryClient.invalidateQueries({
      queryKey: getGetCustomerCartQueryKey({
        products: productsOnCart.map(([_, products]) => products),
        postcode: shippingPostcode,
        coupon,
      }),
    });
  };

  return (
    <Button
      type="button"
      onClick={handleToggleItemToOrder}
      aria-label="Adicionar ao carrinho"
      className={cn(
        'text-[10px] sm:text-xs md:text-[13px] md:leading-[18px]',
        itemAdded &&
          'bg-foreground hover:bg-foreground/90 dark:bg-black dark:hover:bg-neutral-950',
        className,
      )}
    >
      {itemAdded ? 'Adicionado' : 'Adicionar ao carrinho'}
    </Button>
  );
}
