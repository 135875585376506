import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type LoginInput, LoginSchema } from '#/common/schemas/Login.schema';
import { getGetCustomerQueryKey, usePostAuthLogin } from '#/http/api/customer/customer';
import { useToast } from '../components/ui/use-toast';
import { useAuthStore } from '../stores/auth/AuthStoreProvider';

export function useLogin() {
  const { toast } = useToast();
  const router = useRouter();
  const [rememberMe, setRememberMe] = useState(false);
  const updateToken = useAuthStore((state) => state.updateToken);

  const form = useForm<LoginInput>({
    resolver: zodResolver(LoginSchema),
    mode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = usePostAuthLogin({
    mutation: {
      onSuccess: async (data) => {
        updateToken(data.token);
        form.reset();
        toast({ variant: 'success', title: 'Logado com Sucesso!' });

        await queryClient.invalidateQueries({
          queryKey: getGetCustomerQueryKey(),
        });
        router.push('/');
      },
      onError: () => {
        toast({ variant: 'destructive', title: 'Usuário ou senha inválidos' });
      },
    },
  });

  useEffect(() => {
    const cookies = JSON.parse(getCookie('login') || '{}');
    if (cookies) {
      const { username, password } = cookies;
      form.setValue('username', username);
      form.setValue('password', password);
      setRememberMe(true);
    }
  }, [form]);

  const handleSignIn = (input: LoginInput) => {
    if (rememberMe) {
      setCookie('login', input, {
        path: '/',
        secure: true,
      });
    } else {
      deleteCookie('login');
    }
    mutate({
      data: {
        email: input.username,
        password: input.password,
      },
    });
  };

  return {
    form,
    onSubmit: form.handleSubmit(handleSignIn),
    isDisabled: isPending || form.formState.isSubmitting || isSuccess,
    isLoading: isPending || form.formState.isSubmitting,
    handleRememberMe: () => setRememberMe((prevState) => !prevState),
    rememberMe,
  };
}
