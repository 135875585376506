import { useQueryClient } from '@tanstack/react-query';
import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { useGetCustomer } from '#/http/api/customer/customer';
import type { GetCustomer200 } from '#/http/api/generated.schemas';
import { useAuthStore } from '../stores/auth/AuthStoreProvider';

const defaultAddress: GetCustomer200['billing'] | GetCustomer200['shipping'] = {
  address: '',
  complement: '',
  city: '',
  postcode: '',
  country: 'BR',
  state: '',
  state_code: '',
};

export type GetCustomerResponse = GetCustomer200;

const defaultCustomer: GetCustomerResponse = {
  customer_id: 0,
  email: '',
  full_name: '',
  first_name: '',
  last_name: '',
  phone: '',
  tax_id: '',
  theme: 'light',
  billing: defaultAddress,
  shipping: defaultAddress,
  favorite_products: [],
  recently_viewed_products: [],
  payment_method: 'pix',
  cart: [],
};

export function useCustomer() {
  const queryClient = useQueryClient();
  const { setTheme } = useTheme();
  const { isAuthenticated, updateToken } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    updateToken: state.updateToken,
  }));
  const { data, isLoading, error } = useGetCustomer({
    query: {
      enabled: isAuthenticated,
      initialData: defaultCustomer,
    },
  });

  useEffect(() => {
    if (error?.status === 401) {
      updateToken(null);
      queryClient.clear();
    }
  }, [error, updateToken, queryClient]);

  useEffect(() => {
    if (data?.theme) {
      setTheme(data.theme);
    }
  }, [data, setTheme]);

  return { data, isLoading, error };
}
