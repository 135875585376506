import { useCustomer } from './useCustomer';

export const useIsProductFaved = (productId: number) => {
  const {
    data: { favorite_products },
  } = useCustomer();
  const isFaved = favorite_products.some((id) => id === productId);

  return { isFaved };
};
