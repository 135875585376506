import { useQueryClient } from '@tanstack/react-query';
import { useIsProductFaved } from '#/common/hooks/useIsProductFaved';
import { useAuthStore } from '#/common/stores/auth/AuthStoreProvider';
import {
  getGetCustomerQueryKey,
  useDeleteCustomerFavoriteProducts,
  usePostCustomerFavoriteProducts,
} from '#/http/api/customer/customer';
import { Button, type ButtonProps } from '#/ui/button';
import { LoginComponent } from '../Login';
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import { useToast } from '../ui/use-toast';

interface Props extends ButtonProps {
  product: {
    id: number;
    title: string;
  };
}

export function FavoriteButton({ children, product, ...rest }: Props) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { isFaved } = useIsProductFaved(product.id);

  const options = {
    mutation: {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetCustomerQueryKey(),
        });
      },
    },
  };

  const { mutateAsync: addFavorite } = usePostCustomerFavoriteProducts(options);
  const { mutateAsync: deleteFavorite } = useDeleteCustomerFavoriteProducts(options);

  const handleToggleItemToFavorites = async () => {
    if (!isAuthenticated) {
      toast({
        variant: 'destructive',
        title: 'Usuário não autenticado!',
        description: 'É necessário estar logado para salvar o produto nos favoritos',
      });

      return;
    }

    const mutate = {
      data: {
        favorite_products: [product.id],
      },
    };

    if (isFaved) {
      deleteFavorite(mutate).then(() => {
        toast({
          variant: 'delete',
          title: 'Produto removido dos favoritos!',
          description: `${product.title}`,
        });
      });
    } else {
      addFavorite(mutate).then(() => {
        toast({
          variant: 'success',
          title: 'Produto adicionado aos favoritos!',
          description: `${product.title}`,
        });
      });
    }
  };

  if (!isAuthenticated) {
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button
            type="button"
            onClick={handleToggleItemToFavorites}
            aria-label="Adicionar aos Favoritos"
            size="icon"
            variant={'ghost'}
            className="rounded-full p-0 text-brand-main hover:bg-brand-main/5 hover:text-brand-main/90"
            {...rest}
          >
            {children}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <LoginComponent />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Button
      type="button"
      onClick={handleToggleItemToFavorites}
      size="icon"
      aria-label="Adicionar aos Favoritos"
      variant={'ghost'}
      className="rounded-full p-0 text-brand-main hover:bg-brand-main/5 hover:text-brand-main/90"
      {...rest}
    >
      {children}
    </Button>
  );
}
